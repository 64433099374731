body {
    font-size: 14px;
    color: #626262;
    font-weight: 400;
    padding-top: 0;
    letter-spacing: -.02em;
    overflow-x: hidden;
    font-family: 'Open Sans', sans-serif;
    background: rgb(98 98 98 / 5%);
    padding-top: 40px;
}

::selection {
    color: #666666;
    background-color: #f0f0f0;
}


.container-xs {
    max-width: 320px;
}

.bg-gray-100 {
    background: #f9f9f9;
}

.header {
    margin: 0px;
    background: #ffffff;
    border: none;
    padding: 20px 25px;
    box-shadow: 0 0 34px 0px rgb(98 98 98 / 14%);
}

.header img {
    height: 50px;
    width: auto;
}

h1, h2, h3, h4, h5, h6, .roboto {
    font-family: 'Roboto', sans-serif;
}

.header .logo-main {
    width: auto;
    height: 40px;
    min-width: 96px;
}

p {
    margin-bottom: 10px;
    line-height: 26px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #000000;
    line-height: 1.3;
}

h1 {
    font-size: 30px;
    font-weight: 500;
}


.gradient-1 {
    background: linear-gradient(to right, rgba(70, 70, 70, 1) 0%, rgba(105, 105, 105, 1) 100%);

}

.gradient-2 {
}

.panels {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
    min-height: calc(100vh - 70px);
    margin-top: -30px;
}

h2 {
    font-size: 28px;
}

a,
a > *,
.transition {
    -webkit-transition: all 0.4s cubic-bezier(.62, .16, .13, 1.01);
    -moz-transition: all 0.4s cubic-bezier(.62, .16, .13, 1.01);
    -ms-transition: all 0.4s cubic-bezier(.62, .16, .13, 1.01);
    -o-transition: all 0.4s cubic-bezier(.62, .16, .13, 1.01);
    transition: all 0.4s cubic-bezier(.62, .16, .13, 1.01);
}


.btn-primary, .btn-secondary, .btn, .mbsc-windows.mbsc-popup .mbsc-popup-buttons .mbsc-popup-button {
    float: left;
    height: 46px;
    font-size: 15px;
    letter-spacing: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    /* border-radius: 30px; */
    line-height: 1;
    padding: 10px 25px;
    outline: none !important;
    box-shadow: none !important;
    transition: all 0.4s cubic-bezier(.62, .16, .13, 1.01);
    font-family: Roboto;
    font-weight: 600;
}

.btn-primary {
    background: #8B0305;
    color: #ffffff;
}

.btn-secondary {
    color: #ffffff;
    border: 1px solid #464646;
    background: #464646;
}

.btn-primary:hover {
    background: #a70c0f !important;
}

.btn-secondary:hover {
    color: #ffffff;
    background: #000000 !important;
    border: 1px solid #000000;
}

.btn-danger {
    background: #FFD3D3;
    color: #AD3535;
}

.btn-danger:hover {
    background: #AD3535;
    color: #ffffff;
}

.btn-success {
    background: #CAFFEC;
    color: #3C856B;
}

.btn-success:hover {
    background: #b3f2db !important;
}

.btn-primary:focus,
.btn-secondary:focus,
.btn-danger:focus {
}


.btn-primary.w-icon,
.btn-secondary.w-icon {
    justify-content: start;
}

.btn-primary.w-icon svg,
.btn-secondary.w-icon svg {
    margin-left: auto;
    margin-right: -2px;
    height: 20px;
    width: auto;
}

.mb-6 {
    margin-bottom: 30px;
}

.mb-2 {
    margin-bottom: 10px;
}

.mb-3 {
    margin-bottom: 15px;
}

.mb-5 {
    margin-bottom: 25px;
}

.mt-6 {
    margin-top: 30px;
}

.py-3 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.px-5 {
    padding-left: 25px;
    padding-right: 25px;
}

.px-4 {
    padding-left: 20px;
    padding-right: 20px;
}

.gap-2 {
    gap: 12px;
}

.content {
    clear: both;
    float: left;
    width: 100%;
    height: auto;
}

.content > *:first-child {
    margin-top: -7px;
}

.content > *:last-child {
    margin-bottom: -7px;
}

.header .mobile-menu {
    float: right;
    width: 40px;
    height: 40px;
    min-width: 40px;
    margin-left: 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: -7px;
}

.header .mobile-menu span {
    width: 24px;
    position: absolute;
    background: #000000;
    height: 2px;
    transform: rotate(0deg) translate(0px, 0px);
    opacity: 1;
}

.header .mobile-menu span:first-of-type {
    transform: rotate(0deg) translate(0px, -9px);
}

.header .mobile-menu span:last-of-type {
    transform: rotate(0deg) translate(0px, 9px);
}

.ml-3 {
    margin-left: 15px;

}

.mr-3 {
    margin-right: 15px;
}

.header .save-button {
    height: 36px;
    width: auto;
    margin-left: auto;
    padding: 8px 17px;
    font-size: 13px;
    margin-top: 2px;
}

.FormStep {
    float: left;
    width: 100%;
    height: auto;
    display: block;
    margin-top: 0px;
}

.FormStep h1 {
    clear: both;
    float: left;
    font-size: 16px;
    margin: -4px 0 -4px 0;
    font-weight: 600;
}

.FormStep .FormStep-ProgressBar {
    clear: both;
    float: left;
    width: 100%;
    margin-top: 13px;
    margin-bottom: 10px;
}

.panels .ProgressBar,
.FormStep .FormStep-ProgressBar .ProgressBar {
    height: 3px;
    border-radius: 5px;
    background: rgb(84 161 213 / 15%);
}

.panels .ProgressBar .ProgressBar-Progress,
.FormStep .FormStep-ProgressBar .ProgressBar .ProgressBar-Progress {
    height: 3px;
    background: #54a1d5;
}

.FormStep .FormStep-NumberLabel {
    clear: both;
    float: left;
    width: 100%;
    margin-bottom: 30px;
    margin-top: 0px;
}

.FormStep .FormStep-NumberLabel h2 {
    font-size: 18px;
    color: #54a1d5;
    line-height: 1;
    font-weight: 600;
}

.FormStep .FormStep-NumberLabel span {
    color: #54a1d594;
    font-weight: 600;
    font-size: 14px;
}

.FormStep .PreQualification {
}

.gap-6 {
    gap: 30px;
}

.FormStep .FormStep-NavigationButtonGroup {
}

.FormStep .FormStep-NavigationButtonGroup button {
    max-width: 140px;
}

.FormStep .FormStep-Content {
    clear: both;
    float: left;
    width: 100%;
    margin: 0px;
}

.header .save-button svg {
    color: #ffffff;
    fill: #ffffff;
    width: 14px;
    height: auto;
    margin-right: 0px;
    margin-left: 11px;
}

.form-group label.font-bold, .select-input label {
    color: #474747;
    letter-spacing: 0px;
    font-size: 13px;
    line-height: 1;
    float: left;
    width: 100%;
    height: auto;
    margin-top: 0px;
    margin-bottom: 13px;
    position: relative;
    font-weight: 500;
    font-family: 'Roboto';
}

.select-input {
    float: left;
    width: 100%;
    height: auto;
    display: block;
}

.form-group label.font-bold:hover {
    color: #8B0305;
}

.radio-items,
.radio,
.checkbox-list {
    float: left;
    width: 100%;
    display: flex;
    margin-bottom: -13px;
    float: left;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 2px;
}

.radio-items .radio-input,
.radio .radio-fix,
.checkbox-list .input-checkbox {
    margin-bottom: 13px;
    max-width: 48%;
    min-width: 48%;
    margin-right: 0px;
    align-items: start;
    flex: 0 0 48%;
}

.radio-input,
.radio-fix,
.input-checkbox {
    display: flex;
    justify-content: start;
    align-items: center;
}

.radio-input label,
.radio-fix label,
.input-checkbox label {
    margin: 0px;
    font-weight: 400;
    line-height: 22px;
    margin-top: -1px;
    margin-bottom: 0px;
}

.radio-input input, .radio-fix input, .input-checkbox input {
    min-width: 32px;
    height: 32px;
    margin-right: 18px;
    appearance: none;
    -webkit-appearance: none;
    border-radius: 50%;
    background-color: #ffffff;
    border: 1px solid rgb(98 98 98 / 30%);
    box-shadow: 0 0 0px 4px #ffffff inset !important;
    outline: none !important;
    --tw-ring-opacity: 0 !important;
}

.radio-input:hover input,
.radio-fix:hover input,
.input-checkbox:hover input {
    border: 1px solid rgb(98 98 98 / 100%);
}

.radio-input input:checked, .radio-fix input:checked {
    border-color: #8B0305 !important;
    background: #8B0305 !important;
}

.input-checkbox input {
    background-image: url("./Images/checkmark.png");
    border-radius: 0px;
    border-radius: 0px;
    background-size: 16px !important;
    background-position: center;
    background-repeat: no-repeat;
}

.input-checkbox input:checked {
    background-image: url("./Images/checkmark.png");
    box-shadow: 0 0 0px 4px #8B0305 inset !important;
    background-color: #8B0305;
    border-color: #8B0305;
}

.FormStep .FormStep-NavigationButtonGroup {
    clear: both;
    float: left;
    width: 100%;
    margin-top: 30px;
}

.modal-background {
    background: rgb(0 0 0 / 50%);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
}

.px-6 {
    padding-left: 30px;
    padding-right: 30px;
}

.py-6 {
    padding-top: 30px;
    padding-bottom: 30px;
}

.shadow-xl {
    box-shadow: 0 0 26px 0px rgb(40 34 66 / 13%);
}

.modal-content .modal-icon {
    width: 50px;
    height: 50px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    background: #ff4242;
    border-radius: 10px;
    padding: 14px;
}

.modal-content .modal-icon svg {
    width: 100%;
    height: 100%;
    float: left;
    fill: #ffffff;
}

.modal-content h2.text-center {
    clear: both;
    float: left;
    width: 100%;
    height: auto;
    display: block;
    margin-top: -10px;
    margin-bottom: 15px;
}

.modal-content .modal-buttons {
    clear: both;
    float: left;
    width: 100%;
    margin-top: 30px;
}

.modal-content .modal-buttons button {
    max-width: 130px;
}

.mt-1 {
    margin-top: 5px;
}

.mt-2 {
    margin-top: 10px;
}

input.form-input, .form-group textarea, .SignatureCanvas-Canvas, .form-group .textarea,
.false-input {
    float: left;
    width: 100%;
    height: 46px;
    font-size: 14px;
    color: #626262;
    font-weight: 400;
    border: 1px solid rgb(98 98 98 / 30%);
    /* border-radius: 30px; */
    padding: 10px 18px;
    line-height: 24px;
    outline: none !important;
    box-shadow: none !important;
    background: #ffffff;
	appearance:none;
	-webkit-appearance:none;
}

.false-input {
    border: 1px solid rgb(98 98 98 / 15%);
    background: #f9f9f9;
}

.form-group textarea, .form-group .textarea {
    height: 200px;
    border-radius: 0px;
    resize: none;
    line-height: 28px;
}

.form-group .textarea {
    height: auto;
}

.SignatureCanvas-Canvas {
    float: left;
    width: 100%;
    height: auto;
    background: #ffffff;
}

input.form-input:hover, .SignatureCanvas-Canvas:hover {
    border: 1px solid rgb(98 98 98);
}

input.form-input:focus {
    border: 1px solid #8B0305;
}

.form-group .render-icon ~ input.form-input {
    padding-right: 60px;
}

.form-group .render-icon {
    display: none;
}

.form-group svg.render-icon {
    display: block;
    width: 14px;
    height: auto;
    right: 20px;
    fill: #000000;
    stroke: black;
    stroke-width: 3%;
    overflow: visible;
}

.FormStep .FormStep-NavigationButtonGroup button.previous svg {
    margin-right: auto;
    margin-left: -2px;
}

.panels .react-select__control {
    float: left;
    width: 100%;
    height: auto;
    font-size: 14px;
    color: #000000;
    font-weight: 400;
    border: 1px solid rgb(40 34 66 / 21%);
    border-radius: 30px;
    padding: 4px 25px;
    line-height: 1;
    outline: none !important;
    box-shadow: none !important;
    min-height: 46px;
}

.panels .react-select__control:hover {
    border: 1px solid rgb(40 34 66 / 100%);
}

.panels .react-select__control--menu-is-open {
    border: 1px solid #54a1d5;
}

.panels .react-select__control .react-select__value-container {
    padding: 0px;
}


.panels .react-select__control .react-select__value-container .react-select__placeholder {
    margin: 0px;
    color: #000000;
}

.panels .react-select__control .react-select__value-container .react-select__input-container {
    margin: 0px;
    padding: 0px;
    color: #000000;
    border: none !important;
    outline: none !important;
}


.panels .react-select__control .react-select__value-container .react-select__input-container input {
    border: none !important;
    box-shadow: none !important;
}

.panels .react-select__control .react-select__indicators {
}

.panels .react-select__control .react-select__indicators .react-select__indicator-separator {
    display: none;
}

.panels .react-select__control .react-select__indicators .react-select__indicator {
}

.panels .react-select__control .react-select__indicators .react-select__indicator svg {
    width: 16px;
    height: auto;
    fill: #000000;
    transform: rotate(0deg);
    stroke: white;
    stroke-width: 1px;
}

.panels .react-select__control--menu-is-open .react-select__indicators .react-select__indicator svg {
    transform: rotate(180deg);
}

.select-input .form-inputundefined {
    clear: both;
    margin: 0px;
    float: left;
}

.panels .react-select__menu {
    box-shadow: 0 0 26px 0 rgb(40 34 66 / 13%);
    border: 1px solid rgb(40 34 66 / 12%);
    margin: 12px 0 0 0;
    border-radius: 15px;
}

.panels .react-select__menu .react-select__menu-list {
    padding: 6px 0px;
}

.panels .react-select__menu .react-select__menu-list .react-select__option {
    font-size: 13px;
    font-weight: 400;
    color: #000000;
    padding: 10px 17px;
    line-height: 1;
    background: transparent;
}

.panels .react-select__menu .react-select__menu-list .react-select__option:hover {
    background: rgb(40 34 66 / 6%);
}

.panels .react-select__menu .react-select__menu-list .react-select__option--is-selected {
    background: #54a1d5 !important;
    color: #ffffff;
    font-weight: 600;
}

h3 {
    font-size: 18px;
    font-weight: 300;
}

.title-alone {
    line-height: 0.8;
}

.address-placeholder label.font-bold {
    display: none;
}

.css-b62m3t-container {
    clear: both;
    float: left;
    width: 100%;
}

.full-width-splitter {
    float: left;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 1px;
    position: relative;
}

.full-width-splitter:before {
    content: "";
}

.full-width-splitter:before {
    content: "";
    height: 1px;
    background: rgb(40 34 66 / 15%);
    width: 100vw;
    position: absolute;
}

.datepicker .mbsc-material.mbsc-textfield-wrapper .mbsc-material {
}

.datepicker .mbsc-material.mbsc-textfield-wrapper,
.datepicker .mbsc-material.mbsc-textfield-wrapper .mbsc-textfield-inner {
    margin: 0px;
    float: left;
    width: 100%;
    height: auto;
    display: block;
    background: none;
}

.datepicker .mbsc-material.mbsc-textfield-wrapper .mbsc-textfield-ripple {
    display: none;
}

.datepicker .mbsc-material.mbsc-textfield-wrapper input.mbsc-material {
    float: left;
    width: 100%;
    height: 46px;
    background: #ffffff;
    border-radius: 0;
    font-size: 14px;
    color: #000000;
    font-weight: 400;
    border: 1px solid rgb(40 34 66 / 21%);
    display: block;
    padding: 10px 18px;
}

.datepicker .mbsc-material.mbsc-textfield-wrapper input.mbsc-material:hover {
    border: 1px solid rgb(40 34 66 / 100%);
}

.datepicker .mbsc-material.mbsc-textfield-wrapper input.mbsc-material:focus {
    border: 1px solid #8B0305;
}

.mbsc-material.mbsc-popup-body {
    color: #000000;
    box-shadow: 0 0 24px rgb(40 34 66 / 20%);
    border: 1px solid rgb(40 34 66 / 18%);
    border-radius: 0;
    padding: 5px 0;
    margin-top: 7px;
}

.mbsc-material.mbsc-scroller-pointer .mbsc-scroller-wheel-item {
    font-size: 13px;
    color: #000000;
    padding: 0 20px;
    background: rgb(98 98 98 / 0%);
}

.mbsc-material.mbsc-scroller-pointer .mbsc-scroller-wheel-item:hover {
    background: rgb(98 98 98 / 10%);
}

.mbsc-material.mbsc-scroller-pointer .mbsc-scroller-wheel-item.mbsc-selected {
    background: #8B0305;
    color: #ffffff;
    font-weight: 600;
}

body .mbsc-material.mbsc-popup-button.mbsc-font {
    color: #8B0305;
    background: none;
    padding: 0px;
}

body .mbsc-material.mbsc-popup-buttons {
    padding-top: 12px;
}

body .mbsc-material.mbsc-popup-body-anchored,
body .mbsc-material.mbsc-popup-body-center,
body .mbsc-material.mbsc-popup-body-round {
    border-radius: 0px;
}

.mbsc-material.mbsc-scroller-wheel-cont:after,
.mbsc-material.mbsc-scroller-wheel-cont:before {
    opacity: 0;
}

.form-group p.text-sm.text-red-600 {
    font-size: 12px;
    clear: both;
    float: left;
    width: 100%;
    margin: 0px;
    margin-top: 10px;
    font-weight: 600;
    color: #ff3434;
}

.btn-secondary:hover svg {
    fill: #ffffff;
}

.repeater {
    clear: both;
    float: left;
    width: 100%;
    display: block;
}

.repeater > .flex {
    position: relative;
    margin-bottom: 10px;
}

.repeater > .flex.block .btn-primary {
    position: absolute;
    height: 36px;
    width: 36px;
    right: 6px;
    padding: 0px;
    bottom: 5px;
    border: 1px solid red;
    background: none;
    color: red;
    font-size: 25px;
    line-height: 0.8;
    padding: 0 0 5px 1px;
    z-index: 4;
}

.SignatureCanvas .btn-primary {
    border: 1px solid red;
    color: red;
    background: transparent;
    height: 36px;
    padding: 10px 24px;
    width: auto;
    float: right;
}


.repeater > .flex.block .btn-primary:hover,
.SignatureCanvas .btn-primary:hover {
    border: 1px solid #b70000;
    background: none !important;
    color: #b70000;
}

.repeater .repeater-buttons {
    margin-bottom: 0px;
    margin-top: 10px;
}


.repeater .repeater-buttons .add-row {
    height: 36px;
    width: auto;
    font-size: 14px;
    padding: 9px 20px;
}

.remaining {
    clear: both;
    float: left;
    line-height: 0.8;
    margin-top: 16px;
    font-weight: 700;
    font-size: 13px;
    opacity: 0.5;
}

.gap-4 {
    gap: 20px;
}

.row-gap-4 {
	row-gap:20px;
}

.row-gap-2 {
	row-gap:10px;
}

.row-gap-3 {
	row-gap:15px;
}

.ProjectBudget-Breakdown textarea,
.QualificationsAndAchievements-Qualification textarea {
    height: 46px;
    border-radius: 30px;
    line-height: 1;
    padding: 14px 25px;
}

.btn-primary svg {
    fill: #ffffff;
}

.SignatureCanvas-Canvas {
    border-radius: 15px;
    margin-bottom: 10px;
}

.radio-input,
.radio-fix,
.input-checkbox {
    align-items: center;
}

.form-group .filepond--wrapper {
    clear: both;
    float: left;
    width: 100%;
    height: auto;
}

.form-group .filepond--wrapper .filepond--root {
    float: left;
    width: 100%;
    clear: both;
    margin: 0px;
    background: none;
}

.form-group .filepond--wrapper .filepond--root .filepond--credits {
    display: none;
}

.form-group .filepond--wrapper .filepond--drop-label {
    min-height: 46px;
    height: auto;
}

.filepond--drip {
    background: none !important;
}

.filepond--panel-root {
    background: none !important;
}

.form-group .filepond--wrapper .filepond--drop-label label {
    float: left;
    width: 100%;
    background: #ffffff;
    padding: 0px;
    font-size: 14px;
    color: #626262;
    border-radius: 0px;
    text-align: left;
    white-space: normal;
    min-height: 46px;
    padding: 14px 20px;
    padding-right: 55px;
    text-overflow: ellipsis;
    line-height: 1.3;
    font-weight: 400;
    position: relative;
    height: auto;
    border: 1px solid rgb(98 98 98 / 30%);
}

.form-group .filepond--wrapper .filepond--drop-label label:hover {
    border: 1px solid rgb(98 98 98);
}

.form-group .filepond--wrapper .filepond--drop-label label:after {
    content: "\f341";
    font-family: "Font Awesome 5 Pro";
    color: #ffffff;
    position: absolute;
    width: 36px;
    height: 36px;
    text-align: center;
    line-height: 0.8;
    font-size: 14px;
    font-weight: 100;
    padding: 13px 0;
    top: 5px;
    right: 6px;
    background: #464646;
}

.form-group .filepond--wrapper .filepond--list-scroller {
    margin: 0px;
}

.form-group .filepond--wrapper .filepond--list-scroller {
    margin: 0px;
    float: left;
    width: 100%;
    height: auto;
}

.form-group .filepond--wrapper .filepond--list-scroller ul.filepond--list {
    left: 0px;
    right: 0px;
}

.form-group .filepond--wrapper .filepond--list-scroller ul.filepond--list li {
    margin: 0px;
    margin-bottom: 5px;
}

.form-group .filepond--wrapper .filepond--list-scroller ul.filepond--list li {
    margin: 0px;
    margin-bottom: 5px;
    border-radius: 0px;
    overflow: hidden;
}

.ml-1 {
    margin-left: 5px;
}

.Tooltip-Wrapper {
    margin-top: -2px;
    position: relative;
    display: flex;
    justify-content: center;
}

.Tooltip-Wrapper .tooltip-inner {
    width: 16px;
    display: inline-block;
    height: 16px;
    background: #54a1d5;
    color: #ffffff;
    text-align: center;
    font-size: 11px;
    border-radius: 50px;
    line-height: 0.8;
    padding: 3px 0;
}

.Tooltip-Wrapper:hover .tooltip-inner {
    background: #000000;
}

.Tooltip-Wrapper .Tooltip {
    left: -2px;
    font-size: 12px;
    line-height: 1.4;
    border-radius: 0px;
    padding: 9px 12px;
    color: #ffffff;
    background: #666666;
    top: 0px;
    width: 100% !important;
    margin-top: -44px;
}

.repeater .form-group .render-icon ~ input.form-input {
    padding-right: 25px;
}

.section-element__wrap {
    margin: 30px;
}

.btn-link {
    color: #373737;
    font-weight: 600;
}

.btn-link:hover {
    color: #666666;
}

.p-6 {
    padding: 30px;
}

.sidebar {
    z-index: 5;
    background: #ffffff;
    height: 100%;
    left: 0px;
    top: 0px;
    width: 260px;
}

.main-dashboard-container {
    float: left;
    width: 100%;
    min-height: 100vh;
    padding-left: 260px;
}

.header-logged-in h2 {
    font-size: 28px;
    font-weight: 700;
    color: #000000;
    line-height: 1.3;
    margin-top: -6px;
    margin-bottom: -6px;
}

.sidebar .sidebar-logo {
    margin-bottom: 40px;
    float: left;
    width: 100%;
}

.sidebar .sidebar-logo svg {
    max-height: 40px;
    float: left;
}

.sidebar nav {
    height: calc(100%);
}

.sidebar nav ul {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: start;
    flex-direction: row;
}

.sidebar nav ul li {
    float: left;
    width: 100%;
    display: block;
}

.sidebar nav ul li a {
    float: left;
    width: 100%;
    display: flex;
    justify-content: start;
    align-content: center;
    align-items: center;
    line-height: 1.5;
    padding: 0px;
    gap: 0px;
    border-radius: 0px;
    color: #626262;
    text-decoration: none;
    font-size: 16px;
    margin-left: 0px;
    margin-right: 0px;
    font-weight: 500;
}

.sidebar nav ul li a svg {
    width: 24px;
    height: 24px;
    margin-right: 14px;
    transition: all 0.4s cubic-bezier(.62, .16, .13, 1.01);
}

.sidebar nav ul li a svg.my-auto {
    width: 14px;
    height: auto;
    margin: 0px;
    margin-left: auto;
}

.sidebar nav ul li a svg path {
    fill: #000000;
}

.sidebar nav ul.subnav {
    margin-bottom: 50px;
}

.sidebar nav ul.meta-nav li a {
    font-size: 14px;
    font-weight: 500;
}

.sidebar nav ul.subnav > li {
    border-bottom: 1px solid rgb(98 98 98 / 30%);
    margin-bottom: 15px;
    padding-bottom: 15px;
}

.sidebar nav ul.subnav > li:last-of-type {
    margin: 0px;
}

.sidebar nav ul.subnav li a {
}

.sidebar nav ul.subnav li a svg {
}


.sidebar nav ul li a:hover {
    color: #8B0305;
}

.sidebar nav ul li a:hover svg path {
    fill: #8B0305;
}


.sidebar nav ul li a.active,
.sidebar nav ul.subnav > li.relative .top-full li a.active {
    color: #1ab567 !important;
}

.sidebar nav ul li a.active svg path,
.sidebar nav ul.subnav > li.relative .top-full li a.active svg path {
    fill: #1ab567 !important;
}

.sidebar nav ul.meta-nav img {
    float: left;
    width: 30px;
    height: 30px;
    margin-right: 12px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid rgb(98 98 98 / 40%);
}


.sidebar nav ul.meta-nav button.logout {
    display: flex;
    justify-content: start;
    align-items: center;
    align-content: center;
    margin-top: 15px;
    font-size: 14px;
    color: #ff3434;
}

.sidebar nav ul.meta-nav button.logout:hover {
    color: #c51e1e;
}


.sidebar nav ul.meta-nav button.logout svg {
    width: 20px;
    height: 20px;
    margin-right: 7px;
}

.p-5 {
    padding: 25px;
}

.panel-outer {
    border-radius: 0px;
    padding-left: 20px;
    padding-right: 20px;
}

.shadow-sm {
    box-shadow: 0px 0px 22px rgb(98 98 98 / 8%);
}

section header h3, .section-header h3 {
    font-weight: 500;
    font-size: 20px;
}


.text-success {
    color: #28b977;
    font-weight: 700;
}

.sidebar nav ul.subnav > li.relative .top-full {
    padding: 0px;
    background: none;
    border-radius: 0px;
    position: relative;
    float: left;
    padding-left: 38px;
    padding-top: 10px;
    margin-bottom: -10px;
}

.sidebar nav ul.subnav > li.relative .top-full li {
    margin-bottom: 12px;
}

.sidebar nav ul.subnav > li.relative .top-full li a {
    color: #000000;
    font-size: 14px;
    font-weight: 400;
}

.sidebar nav ul.subnav > li.relative .top-full li a:hover {
    @apply text-red-700;
}

.sidebar nav ul.subnav > li.relative .top-full li li {
    margin: 0px;
}

.sidebar nav ul.subnav > li.relative .top-full li button {
    float: left;
    width: 100%;
    height: auto;
    display: block;
}

.sidebar nav ul.subnav > li.relative .top-full li button .fa-chevron-down,
.sidebar nav ul.subnav > li.relative .top-full li button .fa-chevron-up {
    display: block;
}

.sidebar nav ul.subnav > li.relative .top-full li.relative .top-full {
    right: calc(-100% + -22px);
    background: #dfdcef;
    padding-bottom: 8px;
}

.sidebar nav ul.subnav > li.relative .top-full li.relative .top-full li {
    margin-bottom: 12px;
}

.tables table thead th {
    color: #373737;
    font-size: 14px;
    border-bottom: 2px solid;
    line-height: 1.2;
    padding: 0 12px 15px;
}

.tables table td {
    padding: 15px 12px;
    vertical-align: middle;
    border-bottom: 1px solid rgb(98 98 98 / 20%);
}

.tables table tr:hover td {
    border-bottom: 1px solid rgb(98 98 98 / 50%);
}

.btn-danger svg {
    color: #AD3535;
}

.btn-danger:hover svg {
    color: #ffffff;
}

.btn-secondary svg {
    color: #ffffff;
}

.btn-secondary:hover svg {
    color: #ffffff;
}

.icon-only, .tables table td .btn-danger,
.FinancialInformation-Signatories .FinancialInformation-Signatory > button.btn,
.dload-btn .btn,
.sync-project-button .btn {
    width: 46px;
    padding-left: 0px;
    padding-right: 0px;
}

.icon-only svg, .tables table td .btn-danger svg {
    width: 16px;
    height: 16px;
}

.table-pagination p {
    margin: 0px;
    font-size: 12px;
    font-weight: 500;
    color: #464646;
}

.table-pagination p span {
    font-weight: 500;
}


.table-pagination .btn {
    height: 36px;
    padding: 10px 16px;
    color: #464646;
    background: #ffffff;
    border: 1px solid #464646;
    opacity: 1;
    cursor: pointer;
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: 600;
}

.table-pagination .btn:hover {
    background: #464646 !important;
    color: #ffffff;
}

.table-pagination .btn[disabled] {
    opacity: 0.3;
    cursor: default;
    pointer-events: none;
}


.ml-4 {
    margin-left: 20px;
}

body .react-select__control {
    float: left;
    width: 100%;
    height: auto;
    min-height: 46px;
    border: 1px solid rgb(98 98 98 / 30%);
    border-radius: 0px;
    padding: 10px 18px;
    position: relative;
    padding-right: 50px;
}

body .react-select__control:hover {
    border: 1px solid rgb(98 98 98 / 100%);
}


body .react-select__control .react-select__indicators .react-select__indicator-separator {
    display: none;
}


body .react-select__control .react-select__indicators {
    right: 16px;
    position: absolute;
    top: 13px;
}


body .react-select__control .react-select__value-container {
    padding: 0px;
}


body .react-select__control .react-select__value-container .react-select__single-value, body .react-select__control .react-select__value-container .react-select__input-container {
    font-size: 14px;
    color: #000000;
    font-weight: 400;
    padding: 0px;
    margin: 0px;
    border: none;
    box-shadow: none;
}

.section-header .react-select__control {
    min-width: 250px;
}

body .react-select__control .react-select__indicators .react-select__indicator {
    padding: 0px;
    transform: rotate(0deg);
}

body .react-select__control .react-select__indicators .react-select__indicator svg {
    fill: #000000;
    stroke: white;
    stroke-width: 1px;
    width: 18px;
    height: auto;
}

body .react-select__control .react-select__value-container .react-select__input-container input {
    border: none;
    box-shadow: none;
}

body .react-select__control.react-select__control--menu-is-open {
    border: 1px solid #8B0305;
}

body .css-b62m3t-container .react-select__control {
    min-height: 46px;
    box-shadow: none !important;
}


body .react-select__control.react-select__control--menu-is-open .react-select__indicators .react-select__indicator {
    transform: rotate(180deg);
}

body .react-select__menu {
    margin: 0px;
    margin-top: 12px;
    box-shadow: 0px 0px 10px rgb(98 98 98 / 15%);
    background: none;
    z-index: 5;
}

body .react-select__menu .react-select__menu-list {
    border-radius: 0px;
    padding: 4px 0px;
    border: 1px solid rgb(98 98 98 / 15%);
    background: #ffffff;
}

body .react-select__menu .react-select__menu-list .react-select__option {
    font-size: 14px;
    color: #000000;
    padding: 12px 24px;
    background: none;
    cursor: pointer;
}

body .react-select__menu .react-select__menu-list .react-select__option:hover {
    background: rgb(98 98 98 / 5%);
}

body .react-select__menu .react-select__menu-list .react-select__option.react-select__option--is-selected {
    font-weight: 700;
    background: #ffffff;
}

.mbsc-popup-overlay {
    background: rgb(98 98 98 / 50%) !important;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
}

body .mbsc-windows.mbsc-popup,
.confirmation-modal {
    background: #ffffff;
    border: none;
    border-radius: 0;
    box-shadow: 0px 0px 30px rgb(40 34 66 / 19%);
    min-width: 330px;
}

.confirmation-modal {
    max-width: 400px;
}

body .mbsc-windows.mbsc-popup .mbsc-popup-body .mbsc-popup-header {
    color: #464646;
    font-size: 20px;
    line-height: 1.3;
    border: none;
    padding: 20px 25px 20px;
    font-family: Roboto;
    font-weight: 600;
    text-align: left;
}

body .mbsc-windows.mbsc-popup .mbsc-popup-content {
    height: auto;
    border: none;
    overflow: visible;
}

body .mbsc-windows.mbsc-popup .modal-form {
    padding: 0 25px;
}

body .mbsc-windows.mbsc-popup .modal-form .delete-confirmation__message {
    width: 100%;
    text-align: left;
}

body .mbsc-windows.mbsc-popup .mbsc-popup-buttons {
    border: none;
    padding: 35px 25px 25px;
    justify-content: space-between;
}

body .mbsc-windows.mbsc-popup .mbsc-popup-buttons .mbsc-popup-button {
    min-width: 46%;
    flex: 0;
    opacity: 1;
}

body .mbsc-windows.mbsc-popup .mbsc-popup-buttons .mbsc-popup-button.mbsc-popup-button-close {
    color: #3C856B;
    background: #CAFFEC;
}

body .mbsc-windows.mbsc-popup .mbsc-popup-buttons .mbsc-popup-button.delete-confirmation__delete-btn {
    background: #FFD3D3;
    color: #AD3535;
}

body .mbsc-windows.mbsc-popup .mbsc-popup-buttons .mbsc-popup-button.mbsc-popup-button-close:hover {
    background: #b5f5de;
}

body .mbsc-windows.mbsc-popup .mbsc-popup-buttons .mbsc-popup-button.delete-confirmation__delete-btn:hover {
    background: #AD3535;
    color: #ffffff;
}


.tables table td p {
    margin: 0px;
}


.toggleSwitch label {
    font-size: 14px;
    color: #000000;
    padding-right: 15px;
    line-height: 28px;
}

.toggleSwitch {
    display: flex;
    justify-content: start;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}


body .react-select__control .react-select__value-container.react-select__value-container--is-multi .react-select__multi-value .react-select__multi-value__label {
    color: #ffffff;
    font-weight: 500;
    font-size: 12px;
    padding: 0px;
    line-height: 1;
}

body .react-select__control .react-select__value-container.react-select__value-container--is-multi .react-select__multi-value {
    font-size: 14px;
    margin: 0 5px 5px 0;
    --tw-bg-opacity: 1;
    background-color: rgb(153 27 27 / var(--tw-bg-opacity));
    color: #ffffff;
    padding: 8px 11px 6px;
    border-radius: 0px;
}

body .react-select__control .react-select__value-container.react-select__value-container--is-multi .react-select__multi-value .react-select__multi-value__remove {
    padding: 0px;
    width: 22px;
    height: 22px;
    background: none;
    border-radius: 0%;
    float: right;
    margin: -5px -8px -3px 2px;
}

body .react-select__control .react-select__value-container.react-select__value-container--is-multi {
    margin: -3px 0 -8px -9px;
}

body .react-select__control .react-select__value-container.react-select__value-container--is-multi .react-select__multi-value .react-select__multi-value__remove svg {
    fill: #ffffff;
    width: 100%;
    height: 100%;
    padding: 3px;
}

body .react-select__control .react-select__value-container.react-select__value-container--is-multi .react-select__multi-value .react-select__multi-value__remove:hover {
    background: #ff4545;
}

body .react-select__control .react-select__value-container.react-select__value-container--is-multi .react-select__multi-value .react-select__multi-value__remove:hover svg {
    fill: #ffffff;
}


body .react-select__control .react-select__indicators .react-select__clear-indicator {
    margin-right: 6px;
}

body .react-select__control .react-select__indicators .react-select__clear-indicator svg {
    fill: red;
}

.pt-6 {
    padding-top: 30px;
}

.pr-6 {
    padding-right: 30px;
}

.pl-6 {
    padding-left: 30px;
}

nav.nav-tabs, nav[aria-label="Tabs"] {
    float: left;
    width: 100%;
    background: #ffffff;
    padding: 0px;
    border-radius: 0;
}

nav[aria-label="Tabs"] {
    margin-bottom: 40px;
}

nav.nav-tabs a, nav[aria-label="Tabs"] a {
    float: left;
    margin-right: 5px;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    background: transparent;
    color: #000000;
    min-height: 36px;
    display: flex;
    justify-content: center;
    align-items: start;
    align-content: center;
    padding: 0 20px;
    border-radius: 0;
    line-height: 1;
    border-bottom: 2px solid;
    padding-bottom: 15px;
}

nav[aria-label="Tabs"] a {
    position: relative;
    margin: 0px 0px -2px 0px !important;
    text-align: center;
}

nav.nav-tabs a:hover, nav[aria-label="Tabs"] a:hover {
    cursor: pointer;
    color: #999999;
}

nav.nav-tabs a[aria-current="page"], nav[aria-label="Tabs"] a[aria-current="page"] {
    --tw-bg-opacity: 1;
    color: rgb(153 27 27 / var(--tw-bg-opacity));
}

nav[aria-label="Tabs"] a strong {
    position: absolute;
    bottom: -17px;
    background: #000000;
    width: 40px;
    height: 40px;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    padding: 0 5px;
    margin: 0px;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-shadow: 0px 0px 0px 1px #000000;
    color: #ffffff;
}

nav[aria-label="Tabs"] a[aria-current="page"] strong {
    color: #54a1d5;
    box-shadow: 0px 0px 0px 1px #54a1d5;
    background: #ffffff;
}

.toggleSwitch button {
    height: 30px;
    width: 50px;
    border-radius: 30px;
    border: 1px solid rgb(40 34 66 / 21%);
    background: #ffffff;
    box-shadow: none !important;
    position: relative;
}

.toggleSwitch button:hover {
    border: 1px solid rgb(40 34 66 / 100%);
}

.toggleSwitch button span {
    background: rgb(40 34 66 / 21%);
    height: 20px;
    top: 4px;
    position: absolute;
    left: 4px;
    width: 20px;
}

.toggleSwitch button[aria-checked="true"] {
    background: #54a1d5;
    border-color: #54a1d5;
}

.toggleSwitch button[aria-checked="true"] span {
    background: #ffffff;
}


.sidebar nav ul li a[href="#"] {
    filter: grayscale(1);
    opacity: 0.3;
    pointer-events: none;
    cursor: none;
}

body .react-select__control .react-select__value-container.react-select__value-container--is-multi .react-select__placeholder {
    float: left;
    margin: -4px 0 0 0;
    padding-left: 14px;
}

.confirmation-modal .icon-block {
    max-width: 60px;
    padding-right: 15px;
}

.confirmation-modal .icon-block svg {
    fill: #000000;
    background: none;
    border-radius: 50%;
    stroke: white;
    stroke-width: 2%;
}


.confirmation-modal h3 {
    color: #000000;
    font-size: 22px;
    line-height: 1.3;
    font-weight: 700;
    padding-top: 3px;
    margin-bottom: 4px;
}

.address-autocomplete-split .address {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    display: grid;
    gap: 15px 2em;
}

.address-autocomplete-split {
    float: left;
    width: 100%;
    height: auto;
}


.address-autocomplete-split .address .address-placeholder {
    margin-bottom: 0px;
    grid-column: span 1 / span 1;
}

.address-autocomplete-split .address .mb-5 {
    grid-column: span 2 / span 2;
    margin-bottom: 0px;
}

.section-element {
    clear: both;
    float: left;
    width: 100%;
    height: auto;
}

.tabs-pull-panel {
    background: #ffffff;
    padding-bottom: 25px;
    position: relative;
    z-index: 5;
    margin-bottom: -20px;
}

.tabs-pull-panel nav[aria-label="Tabs"] {
    margin-bottom: 0px;
    border: 1px solid rgb(40 34 66 / 50%);
}

.panel-inner .FormStep-NavigationButtonGroup button.next svg {
    margin-left: 15px;
}

.panel-inner .FormStep-NavigationButtonGroup button.previous svg {
    margin-right: 15px;
    margin-left: -2px;
}

.panel-inner .FormStep-NavigationButtonGroup button {
    max-width: fit-content;
}

.panel-inner .FormStep-NavigationButtonGroup {
    margin-bottom: 7px;
}

.panel-inner .full-width-splitter:before {
    width: calc(100% + 50px);
}


.panel-inner nav.nav-tabs {
    margin-bottom: 0px;
    border-bottom: 2px solid #000000;
}

.StatusTabs {
    float: left;
    width: 100%;
    height: auto;
    display: block;
}

.StatusTabs nav[aria-label="Tabs"] a {
    line-height: 1.3;
    min-width: 86px;
    height: auto;
    padding-top: 13px;
    padding-bottom: 28px;
}

.tabs-pull-panel .StatusTabs nav[aria-label="Tabs"] a {
    padding-top: 10px;
    padding-bottom: 10px;
}

.signature-box {
    max-width: 330px;
    height: auto;
    background: #ffffff;
    border: 1px solid rgb(40 34 66 / 21%);
    border-radius: 15px;
    padding: 15px;
}

.signature-box img {
    float: left;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.BankInformation h2 {
    line-height: 0.8;
}

.Activity {
    float: left;
    width: 100%;
    height: auto;
    display: block;
}

.Activity ul.Activity-List {
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -30px;
    margin-top: -20px;
    float: left;
    width: calc(100% + 40px);
}

.Activity ul.Activity-List li.Activity-ListItem {
    float: left;
    width: 100%;
    padding: 15px 20px;
    background: #ffffff;
    border-bottom: 1px solid rgb(98 98 98 / 15%);
    margin: 0px;
}

.Activity ul.Activity-List li.Activity-ListItem:nth-of-type(even) {
    background: #f9f9f9;
}

.Activity ul.Activity-List li.Activity-ListItem:last-of-type {
    border-radius: 0 0 20px 20px;
    border-bottom: none;
}

.Activity ul.Activity-List .top-meta {
    margin-bottom: 20px;
}

.Activity ul.Activity-List p {
    margin: 0px;
    font-size: 12px;
    justify-content: start;
    align-items: center;
    font-weight: 700;
    color: rgb(98 98 98 / 40%);
    line-height: 1;
}

.Activity ul.Activity-List .Tooltip-Wrapper {
    position: relative;
}

.Activity ul.Activity-List .Tooltip-Wrapper .Tooltip {
    white-space: nowrap;
    width: auto !important;
    left: auto;
    right: 0px;
}

.Activity ul.Activity-List .Tooltip-Wrapper time {
    color: rgb(98 98 98 / 40%);
    font-weight: 700;
    font-size: 12px;
}

.Activity ul.Activity-List p svg path {
    fill: white;
}

.Activity ul.Activity-List p svg {
    width: 35px;
    height: 35px;
    background: #1ab567;
    padding: 8px;
    margin-right: 10px;
    box-sizing: border-box;
}

.Activity ul.Activity-List p img.Activity-UserImage {
    margin-right: 14px;
    width: 30px;
    height: 30px;
    border: 1px solid rgb(98 98 98 / 16%);
}

.Activity ul.Activity-List p span {
    color: #000000;
    font-size: 16px;
    font-weight: 700;
    margin-right: 4px;
}

.Activity ul.Activity-List li.Activity-ListItem ul {
    flex-wrap: wrap;
    margin-bottom: -15px;
}

.Activity ul.Activity-List li.Activity-ListItem ul li {
    padding-right: 30px;
    min-width: 33.33333333%;
    flex: 0 0 33.33333%;
    margin-bottom: 15px;
}

.Activity ul.Activity-List li.Activity-ListItem ul li span.chunk {
    font-size: 14px;
    line-height: 1;
    float: left;
    width: 100%;
}

.SignatureCanvas {
    max-width: 330px;
    height: auto;
}

.signature-box .SignatureCanvas canvas.SignatureCanvas-Canvas {
    border: none;
}

.quick-copy {
    margin-left: 10px;
    width: 22px;
    min-width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1ab567;
    border-radius: 50%;
    padding: 3px;
    opacity: 1;
    cursor: pointer;
}

.quick-copy svg {
    fill: #ffffff;
    color: #ffffff;
    height: 12px;
    width: auto;
}

.quick-copy:hover {
    opacity: 0.6;
}

/*.tox-tinymce {*/
/*    height: 300px !important;*/
/*}*/

.Comments,
.Comments ul.Comments-List,
.Comments ul.Comments-List li.Comments-ListItem {
    float: left;
    width: 100%;
    height: auto;
    display: block;
}


.Comments ul.Comments-List {
    margin-bottom: 10px;
}

.Comments ul.Comments-List li.Comments-ListItem {
    margin-bottom: 15px;
}

.Tooltip-Wrapper .Tooltip {
    white-space: nowrap;
    width: auto !important;
    left: auto;
    right: auto;
}

.profile-inner .profile-picture img {
    float: left;
    width: 25px;
    height: 25px;
    margin-right: 12px;
    border-radius: 50%;
    overflow: hidden;
}

.profile-inner .profile-picture span {
    color: #000000;
    text-decoration: none;
    font-size: 13px;
    font-weight: 600;
    line-height: 1;
    margin: 0px;
}

.Tooltip-Wrapper time {
    color: rgb(40 34 66 / 62%);
}

.profile-inner .comment-block {
    background: #f9f9f9;
    border-radius: 0px;
}

.Comments ul.Comments-List li.Comments-ListItem {
    margin-top: 0px;
}

.accordion button[data-headlessui-state] div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    float: left;
    width: 100%;
    height: auto;
    border-top: 1px solid rgb(40 34 66 / 20%);
    color: #000000;
    line-height: 1.3;
    font-size: 18px;
    font-weight: 600;
    border-bottom: 1px solid rgb(40 34 66 / 20%);
    padding: 16px 16px;
    background: #ffffff;
}

.accordion button[data-headlessui-state]:hover div:hover,
.accordion button[data-headlessui-state="open"] div {
    border-bottom: 1px solid rgb(40 34 66 / 100%);
    border-top: 1px solid rgb(40 34 66 / 100%);
}

.accordion button[data-headlessui-state] div svg {
    margin: 0px;
    width: 16px;
    height: auto;
    fill: #000000;
    transform: rotate(0deg);
}

.accordion button[aria-expanded="true"] div svg {
    transform: rotate(0deg);
}

.accordion div[data-headlessui-state] {
    clear: both;
    float: left;
    width: 100%;
    background: #ffffff;
    padding-left: 16px;
    padding-right: 16px;
}

.accordion div[data-headlessui-state="open"] {
    margin-top: -11px;
    border-bottom: 1px solid rgb(40 34 66 / 100%);
    padding-bottom: 25px;
    padding-top: 5px;
}

.accordion button[data-headlessui-state] div {
    margin-bottom: 10px;
}

.Activity ul.Activity-List .top-meta {
    margin-bottom: 0px;
}


.Activity ul.Activity-List li.Activity-ListItem ul li:nth-of-type(1), .Activity ul.Activity-List li.Activity-ListItem ul li:nth-of-type(2), .Activity ul.Activity-List li.Activity-ListItem ul li:nth-of-type(3) {
    margin-top: 15px;
}

.form-group .filepond--wrapper .filepond--drop-label label {
    cursor: pointer;
}

.responsive-tables {
    overflow-x: auto;
    padding-bottom: 10px;
}

.table-pagination {
    margin-top: 25px;
}

.responsive-tables::-webkit-scrollbar {
    height: 10px;
}

/* Track */
.responsive-tables::-webkit-scrollbar-track {
    background: #e5e5e5;
    border-radius: 5px;
    border: 1px solid #e5e5e5;
}
/* Handle */
.responsive-tables::-webkit-scrollbar-thumb {
    background: rgb(255 255 255);
    border: 3px solid #e5e5e5;
    border-radius: 5px;
}

/* Handle on hover */
.responsive-tables::-webkit-scrollbar-thumb:hover {
    background: #000000;
}


.tox-statusbar__branding {
    display: none !important;
}

.highlight-status {
    color: #ffffff !important;
    background: #000000 !important;
}

.extra-row {
    @apply bg-gray-300 !important;
}


.section-header .section-header__main {
    display: flex;
    flex-wrap: wrap-reverse;
    flex-direction: row-reverse;
    align-items: center;
    align-content: center;
}

.section-header .section-header__search,
.section-header .section-header__per-page {
    max-width: 200px;
}

.section-header .section-header__per-page .form-group {
    width: 100%;
}

.section-header .section-header__per-page .react-select__control,
.section-header .section-header__main .react-select__control {
    min-width: 200px;
}

.section-header .section-header__main .css-b62m3t-container, .section-header .section-header__main > .w-full {
    max-width: 200px;
}

.section-header .section-header__main > .flex {
    padding-right: 25px;
}

.section-header .section-header__main .toggleSwitch label {
    max-width: 174px;
    line-height: 1.4;
}

.section-header .section-header__main .toggleSwitch {
    flex-wrap: nowrap;
}

.form-group .filepond--wrapper .filepond--list-scroller ul.filepond--list {
    left: 0;
    right: 0;
    top: 0px;
}

.gap-5 {
    gap: 25px;
}

.sidebar nav ul ul.meta-nav {
    clear: both;
}

.LoginPage label, .LoginPage h1, .LoginPage a {
    @apply text-white !important;
}

.LoginPage .gap-6 {
    gap: 30px;
}

.LoginPage button.btn {
    min-width: 150px;
}

.LoginPage a {
    opacity: 0.6;
}

.LoginPage a:hover {
    opacity: 1;
}

.offline-bar {
    color: #ffffff;
    padding: 10px;
    background: #000000;
}

.offline-bar .btn-link {
    color: #ffffff;
    opacity: 1;
}

.offline-bar .btn-link:hover {
    opacity: 0.6;
}

.TitleActionBar h2 {
    font-size: 24px;
    font-weight: 500;
}

.py-5 {
    padding-top: 25px;
    padding-bottom: 25px;
}

footer p {
    font-size: 12px;
    font-weight: 500;
}

.login-screen {
    margin-top: -40px;
}

.address-placeholder-grid {
    gap: 15px;
}

body .tox-tinymce {
    border: 1px solid rgb(98 98 98 / 30%);
    border-radius: 0px;
}

body .tox-tinymce .tox-statusbar {
    border-top: 1px solid rgb(98 98 98 / 30%);
    background: rgb(98 98 98 / 5%);
}

body .tox-tinymce .tox-editor-header {
    box-shadow: none !important;
    border-bottom: 1px solid rgb(98 98 98 / 30%) !important;
    background: #f9f9f9 !important;
}

body .tox-tinymce .tox-editor-header .tox-menubar, body .tox-tinymce .tox-editor-header .tox-toolbar-overlord, body .tox-tinymce .tox-editor-header .tox-toolbar__primary {
    background: #f9f9f9;
}

body .tox .tox-menu {
    border-radius: 0px;
    border: 1px solid rgb(98 98 98 / 10%);
    box-shadow: 0 0 24px rgb(98 98 98 / 10%);
}

.gravatar-profile {
    border: 1px solid rgb(98 98 98 / 30%);
}

body .tox .tox-mbtn:hover:not(:disabled):not(.tox-mbtn--active),
body .tox .tox-tbtn:hover,
body .tox .tox-collection--list .tox-collection__item--active {
    background: #8B0305;
    border: 0;
    box-shadow: none;
    color: #ffffff;
}

body .tox .tox-collection--list .tox-collection__item--active:not(.tox-collection__item--state-disabled) {
    color: #ffffff;
}

body .tox .tox-collection__item {
    border-radius: 0px;
}

body .tox .tox-mbtn,
body .tox .tox-tbtn {
    border-radius: 0px;
}

body .tox .tox-tbtn:hover svg {
    fill: #ffffff;
}

.toast-banners {
    z-index: 99;
    top: 10px;
    right: 10px;
}

.navbar {
}

.navbar .backdrop-blur {
    background: rgb(0 0 0 / 49%);
    backdrop-filter: blur(3px);
}

.sync-project-button {
    margin-right: 10px;
    margin-top: 2px;
}

.close-modal svg {
    color: red;
}

.close-modal {
    opacity: 1;
}

.close-modal:hover {
    opacity: 0.6;
}

.close-modal {
    margin-bottom: -20px;
    position: relative;
    z-index: 5;
}

.ReportIndexPage .tables table td .icon-only {
    float: none;
    margin: 0 auto;
}

.close-menu {
    z-index: 51;
    fill: red;
    width: 22px;
    height: 26px;
    left: 230px;
    padding: 3px;
    top: 5px;
}

.close-menu button {
    width: 100%;
    height: auto;
    opacity: 1;
}

.close-menu button svg {
    width: 100%;
    height: auto;
}

.close-menu button:hover {
    opacity: 0.6;
}

.close-menu button svg {
    width: 100%;
    height: auto;
}

.ReportIndexPage td .flex.gap-4 {
    justify-content: center;
}

.radio .radio-fix {
    align-items: center;
}

.modal-content {
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: calc(100vh - 80px);
    padding-right: 20px;
}

.modal-content::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.modal-content::-webkit-scrollbar-track {
    background: #ffffff;
}

/* Handle */
.modal-content::-webkit-scrollbar-thumb {
    background: #cccccc;
    border: 3px solid #ffffff;
    border-radius: 10px;
}

/* Handle on hover */
.modal-content::-webkit-scrollbar-thumb:hover {
    background: #666666;
}

.WeeklyReportSection, .ObservationForm .Form, .SnagForm-MultipleForm {
    margin-left: -20px;
    width: calc(100% + 40px);
    border-top: 1px solid rgb(98 98 98 / 15%);
    border-bottom: 1px solid rgb(98 98 98 / 15%);
}

.ObservationForm .tables table td p {
    margin-bottom: 10px;
}


.modal-content {
    max-width: 750px;
}

.modal-outer {
    top: 0px;
}


.ObservationForm .Form.snag-descriptions {
    background: #f5f5f5;
    margin-top: 30px;
    padding: 30px 25px;
    margin-left: -25px;
    width: calc(100% + 50px);
    border-color: #dddddd;
}

.comment-block h1 {
    font-size: 26px;
}

.comment-block h2 {
    font-size: 24px;
}

.comment-block h3 {
    font-size: 22px;
}

.comment-block h4 {
    font-size: 20px;
}

.comment-block h5, .comment-block h6 {
    font-size: 18px;
}

.profile-inner .comment-block {
    position: relative;
}

.profile-inner .comment-block:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 13px solid #f9f9f9;
    position: absolute;
    top: -11px;
    left: 5px;
}

.filepond--file,
.filepond--item-panel {
    border-radius: 0px !important;
}

.ForgotPasswordPage .form-group label.font-bold {
    color: #ffffff;
}

button.btn.back-link {
	color:#000000;
}

button.btn.back-link svg {
    fill: #000000;
}

button.btn.back-link {
    background: none !important;
    height: 36px;
    margin-right: 0;
    padding: 0;
    width: auto;
    gap: 10px;
}

button.btn.back-link:hover {
    padding-left: 10px;
    margin-right: -10px;
}

body .mbsc-material.mbsc-textfield-inner.mbsc-disabled, body .mbsc-material.mbsc-textfield-inner.mbsc-disabled input, body .mbsc-disabled {
    opacity: 1;
    pointer-events: none;
    cursor: default;
}

/* all modal fixes */

.modal-content .modal-form-observation, .modal-content .snag-descriptions {
    padding: 0px;
    background: #ffffff;
    margin-top: 10px;
    min-width: 610px;
}

.modal-content .modal-form-observation .col-span-1 {
    grid-column: span 2 / span 2;
}

.modal-content .modal-form-observation .grid-cols-3, .modal-content .snag-descriptions .grid-cols-3 {
    gap: 20px;
}

.modal-content .modal-form-observation .grid-cols-3 .col-span-1, .modal-content .snag-descriptions .grid-cols-3 .col-span-1 {
    grid-column: span 3 / span 3;
}

.SignatureCanvas-Canvas:disabled, .form-group .textarea:disabled, .form-group textarea:disabled, input.form-input:disabled {
    -webkit-text-fill-color: #626262;
    opacity: 1;
    background: #fff;
    border: 1px solid #6262624d;
    box-shadow: none!important;
    color: #626262;
    float: left;
    font-size: 14px;
    font-weight: 400;
    height: 46px;
    line-height: 24px;
    outline: none!important;
    padding: 10px 18px;
    width: 100%;
}

body .react-select__control .react-select__value-container .react-select__placeholder {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ReportIndexPage td .flex.gap-4 .btn-danger {
    width: auto;
    padding-left: 25px;
    padding-right: 25px;
}   
  
 .pwa-prompt .btn.pwa-prompt {
    white-space: nowrap;
} 

.tables table td img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    object-position: center;
}

.responsive-tables {
    overflow-y: visible;
}
  
@media only screen and (max-width: 1366px) {
.section-header {
    flex-wrap: wrap;
}

.mobile-filters {
    clear: both;
    margin-top: 20px;
}

.section-header .section-header__per-page {
    margin-left: auto;
    margin-right: 0px;
}

.mobile-filters {
    clear: both;
    margin-top: 20px;
    flex-wrap: wrap;
    gap: 25px;
    width: 100%;
}

.mobile-filters .form-group {
    flex: 0 0 25%;
    max-width: calc(25% - 19px);
}

.mobile-filters .form-group .react-select__control {
    min-width: 100%;
}



}



.filepond--root,
.filepond--root .filepond--drop-label {
    height: 200px;
}
.filepond--item {
    height: 200px !important;
}

.form-group .filepond--wrapper .filepond--root {
    height: auto;
    min-height: 48px;
}